import React from 'react';
import './HomeSkills.scss';

import LogoHTML5 from '../../../img/icon-html5.svg';
import LogoJava from '../../../img/icon-java-1.svg';
import LogoJS from '../../../img/icon-js.svg';
import LogoPHP from '../../../img/icon-php.svg';
import LogoCSS from '../../../img/icon-css.svg';
import LogoSASS from '../../../img/icon-sass.svg';
import LogoNode from '../../../img/icon-node-1.svg';
import LogoReact from '../../../img/icon-react.svg';
import LogoAngular from '../../../img/icon-angular.svg';
import LogoLaravel from '../../../img/icon-laravel.svg';
import LogoAWS from '../../../img/icon-aws.svg';
import LogoGCP from '../../../img/icon-gcp.svg';
import SectionTitle from '../../../components/SectionTitle/SectionTitle';

const HomeSkills = React.forwardRef((props, ref) => {
  return (
    <section className="HomeSkills" ref={ref}>
      <SectionTitle
        title="Skills"
        description="Those are my skill set!! I love to learn new technologies!!">
      </SectionTitle>

      <div className="row">
        <div className="row">
          <div className="icon-container">
            <img src={LogoHTML5} alt="HTML5" className="icon"/>
            <p>HTML5</p>
          </div>
          <div className="icon-container">
            <img src={LogoCSS} alt="CSS" className="icon"/>
            <p>CSS</p>
          </div>
          <div className="icon-container">
            <img src={LogoSASS} alt="SASS" className="icon"/>
            <p>SASS</p>
          </div>
        </div>

        <div className="row">
          <div className="icon-container">
            <img src={LogoJava} alt="Java" className="icon"/>
            <p>Java</p>
          </div>
          <div className="icon-container">
            <img src={LogoJS} alt="JS" className="icon"/>
            <p>Javascript</p>
          </div>
          <div className="icon-container">
            <img src={LogoPHP} alt="PHP" className="icon"/>
            <p>PHP</p>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="row">
          <div className="icon-container">
            <img src={LogoNode} alt="Node" className="icon"/>
            <p>Nodejs</p>
          </div>
          <div className="icon-container">
            <img src={LogoReact} alt="React" className="icon"/>
            <p>React</p>
          </div>
          <div className="icon-container">
            <img src={LogoAngular} alt="Angular" className="icon"/>
            <p>Angular</p>
          </div>
        </div>

        <div className="row">
          <div className="icon-container">
            <img src={LogoLaravel} alt="Laravel" className="icon"/>
            <p>Laravel</p>
          </div>
          <div className="icon-container">
            <img src={LogoAWS} alt="AWS" className="icon"/>
            <p>AWS</p>
          </div>
          <div className="icon-container">
            <img src={LogoGCP} alt="GCP" className="icon"/>
            <p>GCP</p>
          </div>
        </div>

      </div>
    </section>
  )
})

export default HomeSkills;