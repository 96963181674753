export const portfolios = [
  {
    id: 1,
    url: 'https://yuki0418.github.io/Canvas/bubble-shooter',
    thumbnail: {
      url: require('../img/portfolio-bubble-shooter.jpg'),
      alt: 'Bubble Shooter'
    },
    title: 'Bubble Shooter Game'
  },
]