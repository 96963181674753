import React, { useState } from 'react';
import './JobWindow.scss';

function JobWindow({job, style}) {
  const [onHover, setOnHover] = useState(false);

  const clickHandler = (event) => {
    // Jump to the job.url
    window.open(job.url, '_blank');
  }

  const mouseOverHandler = (event) => {
    setOnHover(true);
  }
  
  const mouseOutHandler = (event) => {
    setOnHover(false);
  }
  
  return (
    <div
      className={`JobWindow ${job.url ? 'hasLink' : null}`}
      style={{
        ...style,
        backgroundImage: `url(${job.thumbnail.url})`,
      }}
      onClick={() => job.url ? clickHandler() : null}
      onMouseOver={mouseOverHandler}
      onMouseOut={mouseOutHandler}>

      <div className="overlay" style={{ opacity: onHover ? 0 : 1}}></div>
      <label className="title" style={{ opacity: onHover ? 0 : 1}}>{job.title}</label>
    </div>
  )
}

export default JobWindow
