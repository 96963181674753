import React, { useCallback, useEffect, useState } from 'react';
import './SectionNavigation.scss';

const SectionNavigation = ({refs}) => {
  const [symbolPosi, setSymbolPosi] = useState(0);
  const [percentForSec, setPercentForSec] = useState(0);
  
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const scrollHadler = useCallback(event => {
    let rect;
    for(let i = 0; i < refs.length; i++) {
      rect = refs[i].ref.getBoundingClientRect();

      if(rect.top + Math.floor(window.innerHeight / 3) > 0) {
        setSymbolPosi(percentForSec * i);
        return;
      };
    }
  });
  
  const scrollTo = (offsetTop) => {
    window.scroll({
      top: offsetTop,
      behavior: "smooth"
    });
  };

  useEffect(() => {
    // Calc currentOffSet to symbolPosi
    setPercentForSec(Math.floor(100 / (refs.length - 1)));
    scrollHadler();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refs, percentForSec])
  
  useEffect(() => {
    window.addEventListener('scroll', scrollHadler);

    return () => {
      window.removeEventListener('scroll', scrollHadler);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scrollHadler]);

  return (
    <div className={`SectionNavigation ${symbolPosi === 0 ? 'inTop':''}`}>
      <div className="leftline">
        <div className="symbol" style={{top: symbolPosi + '%'}}></div>
      </div>
      {refs.map((ref, i) => <p key={i} onClick={() => scrollTo(ref.ref.offsetTop)} className="label">{ref.label}</p>)}
    </div>
  )
}

export default SectionNavigation;