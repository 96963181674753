import React from 'react';
import './SectionTitle.scss';

function SectionTitle({title, description}) {
  return (
    <div className='SectionTitle u-margin-bottom-medium'>
      <h2 className="heading-2">{title}</h2>
      <div className="border"></div>
      <p>{description}</p>
    </div>
  )
}

export default SectionTitle;
