import React, { useRef, useEffect, useState } from 'react';
import './Home.scss';
import HomeTop from './HomeTop/HomeTop';
import HomeSkills from './HomeSkills/HomeSkills';
import HomeWorks from './HomeWorks/HomeWorks';
import HomePortfolios from './HomePortfolios/HomePortfolios';
import SectionNavigation from '../../components/SectionNavigation/SectionNavigation';

const Home = () => {
  const [refs, setRefs] = useState([]);
  const refTop = useRef(null);
  const refSkills = useRef(null);
  const refWorks = useRef(null);
  const refPortfolios = useRef(null);

  useEffect(() => {
    getRefs();
  }, [refTop, refSkills, refWorks, refPortfolios])
  
  const getRefs = () => {
    setRefs(refs => [...refs, {ref: refTop.current, label: 'Top'}]);
    setRefs(refs => [...refs, {ref: refSkills.current, label: 'Skills'}]);
    setRefs(refs => [...refs, {ref: refWorks.current, label: 'Works'}]);
    setRefs(refs => [...refs, {ref: refPortfolios.current, label: 'Portfolios'}]);
  };
  

  return (
    <div className="Home">
      <SectionNavigation refs={refs}></SectionNavigation>

      <HomeTop ref={refTop}></HomeTop>
      <HomeSkills ref={refSkills}></HomeSkills>
      <HomeWorks ref={refWorks}></HomeWorks>
      <HomePortfolios ref={refPortfolios}></HomePortfolios>
    </div>
  )
}

export default Home;