import React from 'react';
import './HomePortfolios.scss';
import JobWindow from '../../../components/JobWindow/JobWindow';
import {portfolios} from '../../../constants/portfolios';
import SectionTitle from '../../../components/SectionTitle/SectionTitle';

const HomePortfolios = React.forwardRef((props, ref) => {
  return (
    <section className="HomePortfolios" ref={ref}>
      <SectionTitle
        title="Portfolios"
        description="Here my portfolios!! Enjoy some of canvas game!!">
      </SectionTitle>
      
      <div className="works">
        {portfolios.map(job => {
          return <JobWindow job={job} style={{width: '13rem', height: '13rem'}} key={job.id}></JobWindow>
        })}
      </div>
    </section>
  )
});

export default HomePortfolios;