export class Star {
  constructor(x, y, z, ctx) {
    this.x = x;
    this.y = y;
    this.z = z;
    this.opacity = 0;
    this.canvas = ctx.canvas;
    this.ctx = ctx;
    this.speed = 0.005;
  }

  draw = () => {
    this.ctx.globalAlpha = this.opacity;
    this.ctx.translate(this.canvas.width / 2, this.canvas.height / 2);
    this.ctx.beginPath();
    this.ctx.arc(this.x, this.y, this.z, 0, Math.PI * 2, false);
    this.ctx.closePath();
    this.ctx.fillStyle = 'white';
    this.ctx.fill();
    this.ctx.globalAlpha = 1;
    this.ctx.translate(-this.canvas.width / 2, -this.canvas.height / 2);
  };

  update = () => {
    this.opacity += 0.05;
    if(this.isOutOfScreen()) {
      this.resetPosi();
    } else {
      this.x += this.x * this.speed * this.z;
      this.y += this.y * this.speed * this.z;
    }
  };

  isOutOfScreen = () => {
    if(
      this.x < -(this.canvas.width / 2) || this.x > this.canvas.width / 2 ||
      this.y < -(this.canvas.height / 2) || this.y > this.canvas.height / 2
    ) {
      return true;
    }
    return false;
  };

  resetPosi = () => {
    const s = {
      x: Math.random() * this.canvas.width - this.canvas.width / 2,
      y: Math.random() * this.canvas.height - this.canvas.height / 2,
      z: Math.random() * 2
    }
    this.x = s.x;
    this.y = s.y;
    this.z = s.z;
    this.opacity = 0;
  }
}
