import React from 'react';
import './HomeWorks.scss';
import JobWindow from '../../../components/JobWindow/JobWindow';
import { works } from '../../../constants/works';
import SectionTitle from '../../../components/SectionTitle/SectionTitle';

const HomeWorks = React.forwardRef((props, ref) => {
  return (
    <section className="HomeWorks" ref={ref}>
      <SectionTitle
        title="Works"
        description="As a freelancer, I helped companies for thier buisinesses.">
      </SectionTitle>
      <div className="works">
        {works.map(job => {
          return <JobWindow job={job} style={{width: '13rem', height: '13rem'}} key={job.id}></JobWindow>
        })}
      </div>
    </section>
  )
})

export default HomeWorks;