import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import './HomeTop.scss';
import { Star } from './Star';

const Canvas = () => {
  const [size, setSize] = useState([0, 0]);
  const canvas = useRef(null);
  // const parentElm = useRef(null);
  let ctx;
  const stars = [];
  const numStars = 200;

  const setup = () => {
    ctx = canvas.current.getContext('2d');

    for(let i = 0; i < numStars; i++) {
      const s = {
        x: Math.random() * size[0] - size[0] / 4,
        y: Math.random() * size[1] - size[1] / 4,
        z: Math.random() * 2
      }
      stars.push(new Star(s.x, s.y, s.z, ctx));
    }
  }

  const draw = () => {
    clear();

    for(let i in stars) {
      stars[i].update();
      stars[i].draw();
    }

    window.requestAnimationFrame(draw);
  }

  const clear = () => {
    ctx.fillStyle = 'black';
    ctx.fillRect(0,0, canvas.current.width, canvas.current.height);
  }

  useEffect(() => {
    setup();
    window.requestAnimationFrame(draw);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canvas.current]);

  useEffect(() => {
    canvas.current.width = size[0];
    // canvas.current.height = size[1];
  }, [size]);

  // Detect screen size change
  useLayoutEffect(() => {
    const winHeight =window.innerHeight;
    const updateSize = () => {
      setSize([window.innerWidth, winHeight]);
    };
    
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);

  // return <div ref={canvas}>{size[0] + ',' + size[1]}</div>;
  return <canvas ref={canvas} id='canvas' width={size[0]} height={size[1]}></canvas>;
}

const HomeTop = React.forwardRef((props, ref) => {
  return (
    <section className='HomeTop' ref={ref}>
      <Canvas></Canvas>
      <div className="content">
        <h1 className="heading-1">Konnichiwa, I'm Yuki</h1>
        <h1 className="heading-1">Full Stack Web Developer</h1>
      </div>
    </section>
  );
})

export default HomeTop;