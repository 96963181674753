import React from 'react';
import { BrowserRouter as Router, Route } from "react-router-dom";
import './App.scss';
import Footer from '../../components/Footer/Footer';
import Home from '../Home/Home';

function App() {
  return (
    <Router>
      <div className="App">
        <Route exact path="/" component={Home} />
        <Footer></Footer>
      </div>
    </Router>
  );
}

export default App;
