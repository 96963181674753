import React from 'react';
import './Footer.scss';
import {FaGithubSquare, FaTwitterSquare, FaLinkedin} from 'react-icons/fa';

function Footer() {
  return (
    <div className="Footer">
      <div className="icons">
        <a href="https://www.linkedin.com/in/yuki-ishii-b92891190/" target="_blank" rel="noopener noreferrer">
          <FaLinkedin size="2rem" className="icon" />
        </a>
        <a href="https://twitter.com/YukiIshii10" target="_blank" rel="noopener noreferrer">
          <FaTwitterSquare size="2rem" className="icon" />
        </a>
        <a href="https://github.com/yuki0418" target="_blank" rel="noopener noreferrer">
          <FaGithubSquare size="2rem" className="icon" />
        </a>
      </div>
      <p className="copyright">&copy; 2020 Yuki Ishii</p>
    </div>
  )
}

export default Footer
