export const works = [
  {
    id: 1,
    url: null,
    thumbnail: {
      url: require('../img/work-web.jpg'),
      alt: 'Real Estate'
    },
    title: 'Real Estate Companies Website'
  },
  {
    id: 2,
    url: null,
    thumbnail: {
      url: require('../img/work-web.jpg'),
      alt: 'Reservel'
    },
    title: 'Reservel Booking Web App'
  },
  {
    id: 3,
    url: null,
    thumbnail: {
      url: require('../img/work-web.jpg'),
      alt: 'Hi-End Music'
    },
    title: 'Instrumental Company Website'
  },
]